.mbdharbg{
    background-color: rgb(255, 236, 219);
}


.navbutton{
    background-color: #9a5f20; 
    border: none;
    color: white;
    padding: 5px 12px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border-radius: 4px;
    font-size: 14px;
}

.berhamporekhagra{
    background-color: #9a5f20; 
    border: none;
    color: rgb(255, 255, 255);
    padding: 5px 12px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border-radius: 4px;
    font-size: 14px;
}