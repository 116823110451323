.body{
    height: 1000vh;
   }
 
 
 
   .containers{
     
     height:  500px;
     width: 100%;
     background-position: center;
     background-size: cover;
     background-image: url(https://i.postimg.cc/HsBTwCjf/Picsart-22-05-26-12-17-07-942.jpg);
     background-attachment: fixed;
     color: white;
     display: flex;
     justify-content: center;
     align-items: center;
 
   }



   .containerse{
     
    height:  500px;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-image: url(https://i.postimg.cc/HsBTwCjf/Picsart-22-05-26-12-17-07-942.jpg);
    background-attachment: fixed;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;

  }

  .mbdhar{
    font-family: 'Exo 2', sans-serif;
  }

  .sentancestyle{
    font-family: 'Roboto', sans-serif;
  }
  .sentancestyles{
    font-family: 'Ubuntu', sans-serif;
  }

  .appointmentbg{
    background-color: rgb(223, 227, 238);
}


.mbd h2{
  font-family: "Dancing Script", cursive;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: normal;
}

.homebuttoncolor{
  background-color: #9a5f20; /* Green */
  border: none;
  color: rgb(255, 255, 255);
  padding: 5px 12px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 4px;
  font-size: 14px;
}

.goldcolor h1{
  color: #9a5f20;
}

.gold h5{
  color: #9a5f20;
}

.callbutton{
  background-color: #9a5f20; /* Green */
  border: none;
  color: rgb(255, 255, 255);
  padding: 5px 12px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 4px;
  font-size: 14px;
}

.gold h2{
  color: #9a5f20;
}



.card:hover {

  background-color: rgb(244, 241, 233);

  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);

  transform: scale(1.03); /* Slightly zoom in on hover */

}
