.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container{
  font-family: "Exo 2", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;

}

.buttoncall{
  background-color: #8a610e; /* Green */
  border: none;
  color: white;
  padding: 5px 14px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 4px;
  font-size: 13px;
  
}

.buttoncall:hover{
  background-color: rgb(183, 127, 14);
}


.buttonastro{
  background-color: #C0821A; /* Green */
  border: none;
  color: white;
  padding: 7px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 8px;
  font-size: 16px;
}

.buttonwhatsapp{
  background-color: rgb(74, 150, 77); /* Green */
  border: none;
  color: white;
  padding: 5px 14px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 13px;
  border-radius: 5px;
}

a {
  text-decoration: none !important;

  
}


.btn-grad15 {
  background-image: linear-gradient(to right, #740427 0%, #bc1919  51%, #2b5876  100%);
  margin: 0px;
  padding: 9px 29px;
  text-align: center;
  /* text-transform: uppercase; */
  transition: 0.5s;
  background-size: 200% auto;
  color: white;            
  box-shadow: 0 0 20px #eee;
  border-radius: 4px;
  display: block;
}

.btn-grad15:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}


.btn-grad13 {
  background-image: linear-gradient(to right, #8d5f0b 0%, #573807  51%, #ef0505  100%);
  margin: 0px;
  padding: 9px 29px;
  text-align: center;
  /* text-transform: uppercase; */
  transition: 0.5s;
  background-size: 200% auto;
  color: white;            
  box-shadow: 0 0 20px #eee;
  border-radius: 2px;
  display: block;
}

.btn-grad13:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.btn-gradnav {
  background-image: linear-gradient(to right, #9f0101 0%, #422b22  51%, #ef0505  100%);
  margin: 0px;
  padding: 4px 20px;
  text-align: center;
  /* text-transform: uppercase; */
  transition: 0.5s;
  background-size: 200% auto;
  color: white;            
  box-shadow: 0 0 20px #eee;
  border-radius: 2px;
  display: block;
}

.btn-gradnav:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.backcolor{
  background-color: #baafa0;
  color: white;
}

.colorbutton{
  background-color: #957009; /* Green */
  border: none;
  color: white;
  padding: 4px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
border-radius: 4px;
  font-size: 9px;
}

.colorbutton:hover {
 color: #f2fcff;
}

.colorbuttonwhatsapp{
  background-color: #2a6e55; /* Green */
  border: none;
  color: white;
  padding: 4px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 4px;
  font-size: 10px;
}

.colorbacklockt{
  background-color: #e0dcd4;
}


.goldberhampore{
  background-color: #fffbef;
}





* {box-sizing: border-box;}

.img-zoom-container {
  position: relative;
}

.img-zoom-lens {
  position: absolute;
  border: 1px solid #d4d4d4;
  /*set the size of the lens:*/
  width: 40px;
  height: 40px;
}

.img-zoom-result {
  border: 1px solid #d4d4d4;
  /*set the size of the result div:*/
  width: 300px;
  height: 300px;
}

.border-radius img{
  border-radius: 18px;
}

.buttonsonarsonsar{
  background-color: #925714;
  border: none;
  color: white;
  padding: 5px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 7px;
  font-size: 15px;
}

.ramnabami{
  background-color: #581303;
  padding: 13px;
}


.borderradius img{
border-radius: 14px;
}