body{
    background: #e9e7f3;

}



.card-img-top{
    width: 100%;
    height: 100%;
    object-fit: cover;
}


  .slick-slide > div {
    margin: 0 10px;
}


.slick-list {
    margin: 0 -10px;
}

.animat{
    width: 87%;
    margin: 0 auto;
    margin-top: 45px;
}

.fonts{
    font-size: 13px;
}